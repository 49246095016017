import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./about-hero.yaml"

const AboutHero = () => {
  return (
    <section className="whoweare_area pad_btm">
      <Container className="p0" fluid>
        <Row className="no-gutters">
          <Col
            xs={{ order: 2, span: 12 }}
            lg={{ order: 1, span: 5 }}
            xxl={{ order: 1, span: 4 }}
            className="whoweare_content"
          >
            <div className="whoweare_content_ph">
              <h2>{YAMLdata.title}</h2>
              <p className="black-dash">
                {YAMLdata.section1} <br />
                <br />
                {YAMLdata.section2}{" "}
              </p>
            </div>
          </Col>
          <Col
            xs={{ order: 1, span: 12 }}
            lg={{ order: 2, span: 7 }}
            xxl={{ order: 2, span: 8 }}
            className="whoweare_image"
          >
            <StaticImage
              src="../images/about/about-image.jpg"
              alt="About Us"
              layout="fullWidth"
            />
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default AboutHero
