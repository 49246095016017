import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import AboutHero from "../sections/about-hero"
import AboutTeam from "../sections/about-team"

const WhoWeAre = () => (
  <Layout>
    <Seo
      title="Who We Are"
      description="Team WDA is diverse and multi-disciplinary. Established in 2010 by Principal Architect Jatin Hukkeri, WDA has gained deep experience in the F&B, Hospitality, Retail & Entertainment domains with over 70 dedicated projects within this segment. We are a young and restless team of architects and interior designers, with a keen instinct for future trends."
    />
    <AboutHero />
    <AboutTeam />
  </Layout>
)

export default WhoWeAre
