import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Row, Container, Col } from "react-bootstrap"

import YAMLdata from "./about-team.yaml"

const AboutTeam = () => {
  return (
    <section className="team_area pad_btm">
      <Container className="p0">
        <div className="team_inner">
          <Row className="no-gutters">
            <Col
              lg={{ span: 7, offset: 0 }}
              xl={{ span: 6, offset: 1 }}
              className="team_image"
            >
              <StaticImage
                src="../images/about/team.jpg"
                alt="Our Team"
                layout="fullWidth"
              />
            </Col>
            <Col lg={4} className="team_content">
              <div className="team_content_ph">
                <h2 className="black-dash">{YAMLdata.title}</h2>
                <p>
                  {YAMLdata.section1}
                  <br />
                  <br />
                  {YAMLdata.section2}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}
export default AboutTeam
